import appConfig from "@libs/common/app-config.json";
import axios from "axios";
import { routePath } from "../../components/Route";
import { authKey, statusFail, statusSuccess } from "../common/Constant";
import http from "../common/request";
import requestV2 from "../common/requestV2";
import requestV3 from "../common/requestV3";

export class UserService {
  _login = `/login`;
  check_status = `/check_status`;
  _register = `/register`;
  _logout = `/logout`;
  _apiGetUsers = `/users`;
  _apiGetRoles = `/roles`;
  _apiSetPermission = `/setroles`;
  _apiGetUserById = `/getuser/`;
  _apiUpdateUser = `/user/update`;
  _apiResetPassword = `/user/resetpass`;
  _apiUpdateUserInfo = `/user/updateuserinfo`;

  login({ email, password }) {
    return new Promise((resolve, reject) => {
      axios
        .post(this._login, { email: email, password: password })
        .then((response) => {
          if (response.data.status === statusFail) {
            resolve(response.data);
          }

          if (response.data.auth_token) {
            let payload = this._decodeToken(response.data.auth_token);
            this.setCookie(
              "Authorization",
              response.data.auth_token,
              payload.exp
            );
            localStorage.setItem(authKey, response.data.auth_token);
            return resolve(response.data);
          }
          return reject();
        })
        .catch(reject);
    });
  }

  getToken = () => localStorage.getItem(authKey);

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = " expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + " path=/";
  }

  isAuthorized() {
    return Boolean(this.decodeToken());
  }

  logout() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem(authKey);
      localStorage.removeItem(authKey);
      this.setCookie("Authorization", "", 0);
      resolve();
    });
  }

  register(user) {
    return new Promise((resolve, reject) => {
      axios
        .post(this._register, user)
        .then((data) => {
          if (data.data.status === statusSuccess) {
            localStorage.setItem(authKey, data.data.auth_token);
          }
          //this.login(user).then(resolve).catch(reject)

          return resolve(data.data);
          //return this.checkStatus().then(resolve).catch(reject)
        })
        .catch((error) => reject(error));
    });
  }

  checkStatus() {
    let token = localStorage.getItem(authKey);
    return new Promise((resolve, reject) => {
      if (token) {
        axios
          .post(this.check_status, { token: token })
          .then((res) => resolve(res.data))
          .catch(reject);
      } else {
        return reject();
      }
    });
  }

  setLoginReturnUrl(url) {
    if (url) {
      localStorage.setItem("returnUrl", url);
    } else {
      localStorage.removeItem("returnUrl");
    }
  }

  getLoginReturnUrl() {
    return localStorage.getItem("returnUrl") || routePath.dashBoard;
  }

  getUsers(params) {
    return requestV3.get("user.getall", { params });
  }

  getUserById(id) {
    return requestV3.get(`user.getbyid/${id}`);
  }

  create = (model) => requestV3.post("user.create", model);

  async getRoles() {
    return await http.get("/roles");
  }

  getCurrentUser() {
    if (this.decodeToken()) {
      let { sub } = this.decodeToken();
      sub.isAuthorized = true;
      sub.canEdit =
        appConfig.roles.canEdit.any((x) => sub.roles.any((e) => e == x)) ||
        sub.isAdmin;
      return sub;
    }
    return {
      userName: "",
      email: "",
    };
  }

  checkRoles(callback) {}

  setRole(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(this._apiSetPermission, params)
        .then((res) => resolve(res.data))
        .catch(reject);
    });
  }

  updateUser(user) {
    return requestV3.put("user.update", user);
  }

  checkEmailAlreadyUsed = (email, user_id) =>
    requestV3.post("user.checkemail", { email, user_id });

  checkEmailExists(email) {
    return requestV3.post("account.find_email", { email });
  }

  verifyCode(email, code, timestamp) {
    return requestV3.post("account.verify_code", { email, code, timestamp });
  }

  updatePassword(email, password) {
    return requestV3.post("account.reset_password", { email, password });
  }

  async updateProfile(user) {
    const data = await http.post("/user/update-profile", { user });
    if (data.auth_token) {
      localStorage.setItem(authKey, data.auth_token);
    }
  }

  updateUserInfo(user) {
    return requestV2.put("system/user", user);
  }

  async deleteUserById(id) {
    return requestV3.delete(`user.delete/${id}`);
  }

  async recoveryPassword(email) {
    return await http.get("/account/recovery", { params: { email } });
  }

  resetPassword(email) {
    return requestV3.post("account.recovery_password", { email });
  }

  getSimpleUsers() {
    return new Promise((resolve, reject) => {
      axios
        .get(this.domain + "/users/simple")
        .then((res) => resolve(res.data))
        .catch(reject);
    });
  }

  async verifyAccount(token) {
    return await http.get("/user/verify-account", {
      params: {
        token: token,
      },
    });
  }

  async reverifyAccount(token) {
    return await http.get(this.domain + "/user/reverify-account", {
      params: {
        token: token,
      },
    });
  }

  async lockUser(id) {
    return await requestV3.post(`/user.lock/${id}`, {
      is_locked: true,
    });
  }

  async unlock(id) {
    return await requestV3.post(`/user.lock/${id}`, { is_locked: false });
  }
}

export default new UserService();
