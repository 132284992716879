import { observable, action, computed, toJS } from "mobx";
import AssessmentService from "@libs/services/AssessmentService";

export default class StatModalStore {
  @observable isVisible = false;
  @observable _fields = [];

  constructor() {
    this.optionsFieldService = new AssessmentService();
  }

  @action
  async fetchStats() {
    this._fields = [];
    this._fields = await this.optionsFieldService.getAssessments();
  }

  @action
  async setStats(stats) {
    this._fields = stats;
  }

  @computed
  get stats() {
    return toJS(this._fields);
  }
}