import { BaseService } from "./BaseService";
import Axios from "axios";
import { statusSuccess } from "../common/Constant";
import http from "../common/request";

export class CountyService extends BaseService {
  _apiGetCouties = `/counties`;
  _apiGetCountiesByStates = `/counties/states?states=`;
  _apiGetCountyById = `/county/`;
  constructor() {
    super();
  }

  getCounties(stateIds = []) {
    return http.post(this._apiGetCouties, { stateIds });
  }

  getCountyById(id) {
    let api = `${this._apiGetCountyById}${id}`;
    return http.get(api);
  }

  async getCountiesByState(states) {
    const { counties } = await http.post(this._apiGetCountiesByStates, { states });
    return counties;
  }

  async getCountiesAutoComplete(stateId, name) {
    return await http.get('/autocomplete/counties/' + stateId, {
      params: {
        name
      }
    });
  }
}

export default new CountyService();