import { observable, computed, toJS, action } from "mobx";
import postManagerService from "@libs/services/postManagerService";

export default class PostStore {
    @observable _dataSource = {};
    @observable _posts = [];
    @observable _filter = {};
    @observable _authors = [];
    @observable _post = {
        author: {},
        owner: {}
    };

    @computed
    get authors() {
        return toJS(this._authors);
    }

    @computed
    get filter() {
        return toJS(this._filter);
    }

    @computed
    get dataSource() {
        return toJS(this._dataSource);
    }

    @computed
    get posts() {
        return toJS(this._posts);
    }

    @computed
    get post() {
        return toJS(this._post);
    }

    @action
    async hiddenPost(id) {
        await postManagerService.hidden(id);
    }

    @action
    async searchAuthor(name) {
        this._authors = await postManagerService.searchAuthor(name);
    }

    @action
    async getPostById(id) {
        this._post = await postManagerService.getById(id);
    }

    @action
    setPost(post) {
        this._post = post;
    }

    @action
    async fetch(params) {
        this._filter = params || {};
        this._dataSource = await postManagerService.fetch(this._filter);
        this._posts = this._dataSource.posts || [];
    }
}