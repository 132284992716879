import { routePath } from "@components/Route";
import AssessmentService from "@libs/services/AssessmentService";
import { AthleteService } from "@libs/services/AthleteService";
import { notification } from "antd";
import { action, computed, observable, toJS } from "mobx";

export class AthleteDetailStore {
  @observable _athlete = {};
  @observable _stats = [];

  constructor() {
    this.athleteService = new AthleteService();
    this.assessmentService = new AssessmentService();
  }

  @action
  async fetchStats(athleteId) {
    this._stats = await this.assessmentService.getAthleteAssessment(athleteId);
  }

  @action
  setStats(values) {
    this._athlete.stats_json = [...values]
  }

  @action
  async fetchAthleteDetail(id) {
    this.athleteService.getAthleteDetail(id)
      .then(athlete => this._athlete = athlete)
  }

  @action
  setAthleteDetail(athlete) {
    this._athlete = athlete || {};
    if (athlete && athlete.id) {
      this.fetchStats(athlete.id);
    } else {
      this.fetchStats(-100);
    }
  }

  @action
  async saveChange(athleteObject, history) {
    let isEdit = false;
    if (athleteObject.id === 0 || !athleteObject.id) {
      this._athlete = await this.athleteService.insert(athleteObject);
      history.push(routePath.detailAthlete.replace(':id', this._athlete.id));
    } else {
      this._athlete = await this.athleteService.update(athleteObject);
      isEdit = true;
    }

    !isEdit ? notification.success({ message: 'Insert Success!' }) : notification.success({ message: 'Update Success!' });;
  }

  @computed
  get athlete() {
    return toJS(this._athlete);
  }

  @computed
  get stats() {
    return toJS(this._stats);
  }
}