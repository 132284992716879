import App from "@containers/App";
import { fab } from '@fortawesome/free-brands-svg-icons';
import * as icons from '@fortawesome/free-solid-svg-icons';
import abp from '@libs/common/abp';
import authorizationService from '@libs/services/AuthorizationService';
import '@styles/theme.scss';
import { Provider as MobxProvider } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import initializeStores from './mobxStores/initStore';
import { persistor, store } from "./stores";


authorizationService.fetchRoles()
  .then(roles => {
    abp.icons = { ...icons, ...fab }
    for (const role of roles)
      abp.auth.allPermissions[role.accesstype] = { ...role }
  })
  .then(async () => {
    if (authorizationService.isAuthorized()) {
      const userRoles = await authorizationService.fetchRolesByUser();
      for (const role of userRoles)
        abp.auth.grantedPermissions[role.accesstype] = { ...role }
    }
  })
  .then(() => {
    const mobxStores = initializeStores()
    ReactDOM.render(
      <MobxProvider {...mobxStores}>
        <ReduxProvider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </ReduxProvider>
      </MobxProvider>,
      document.getElementById('app')
    )
  })
