import { observable, computed, toJS, action } from "mobx";
import registerCoachHighSchooServicel from "@libs/services/registerCoachHighSchooServicel";

export default class RegisterStore {
    @observable _formData = null;
    @observable _isLoadding = false;

    @computed
    get isLoading() {
        return this._isLoadding;
    }

    @computed
    get registerModel() {
        return toJS(this._formData);
    }

    get highSchoolType() {
        return {
            existing: {
                value: 1,
                label: 'Existing'
            },
            new: {
                value: 2,
                label: 'New'
            }
        }
    }

    get genderType() {
        return {
            male: {
                label: 'Male',
                value: 1
            },
            female: {
                label: 'Female',
                value: 0
            }
        };
    }

    @action
    setModel(model) {
        if (model) {
            this._formData = {};
            this._formData.first_name = model.firstName;
            this._formData.last_name = model.lastName;
            this._formData.title = model.title;
            this._formData.email = model.email;
            this._formData.password = model.password;
            this._formData.gender = model.gender;
            if (model.dob) {
                this._formData.dob = model.dob.toISOString();
            }

            if (model.hsType === this.highSchoolType.existing.value) {
                this._formData.high_school = {
                    id: model.highSchoolId
                };
            } else {
                this._formData.high_school = {
                    name: model.highSchoolName,
                    city: model.highSchoolCity,
                    address: model.highSchoolAddress,
                    state_id: model.highSchoolStateId,
                    county_id: model.highSchoolCountyId
                }
            }
        }
    }

    @action
    register(callback = {}) {
        if (this._formData) {
            this._isLoadding = true;
            registerCoachHighSchooServicel.register(this._formData).then(data => callback && callback.redirect()).finally(() => this._isLoadding = false);
        }
    }
}