import requestV2 from "@libs/common/requestV2";
import Axios from "axios";
import { statusSuccess } from "@libs/common/Constant";
import mobileHttp from "@libs/common/mobileRequest";
import mobileHttpV2 from "@libs/common/mobileRequestV2";
import http from "@libs/common/request";
import requestV3 from "@libs/common/requestV3";
import { BaseService } from "./BaseService";
import ExportExcelService from "./ExportExcelService";
import urlcat from "urlcat";
import requestV2Mobile from "@libs/common/requestV2Mobile";

export class AthleteService extends BaseService {
  constructor() {
    super()
    this._apiSaveAthlete = `/athlete/save`;
    this._apiAthleteDelete = `/athlete/delete`;
    this._apiExport = `/athlete/export`;
    this._exportService = new ExportExcelService();
  }

  getGridData = (pageIndex, pageSize, filter = {}, sorted) => {
    return requestV3.post('athlete.getall', { pageIndex, pageSize, filter, sorted });
  }
  getAthleteInSubscription = (params) => {
    return requestV2Mobile.get("manage/athletes", {
      params: {
        ...params, page_size: params.pageSize, user_type: "1", userType: "1"
      }
    });
  }
  getLiveUsers(athleteIds) {
    return requestV2.post('manage/athletes/users', athleteIds)
  }

  getAthleteDetail(athleteId) {
    return requestV3.get(`athlete.getbyid/${athleteId}`);
  }

  import = files => {
    const formData = new FormData()
    formData.append('file', files)
    return requestV3.post('athlete.tool.import', formData)
  }

  saveAthlete(athleteObject, token) {
    let config = {
      headers: {
        Authorization: token
      }
    }
    return new Promise((resolve, reject) => {
      Axios.post(this._apiSaveAthlete, {
        athlete: athleteObject
      }, config).then(res => {
        if (res.data.status === statusSuccess) {
          return resolve(res.data);
        }
        return reject(res.data);
      }).catch(reject);
    });
  }

  insert(athleteObject) {
    return requestV2.post('manage/athlete', athleteObject);
  }

  insertBatch = athletes =>
    requestV3.post('athlete.insertBatch', athletes)

  update = (athleteObject) => {
    return requestV3.put('athlete.update', athleteObject)
      .then(() => this.getAthleteDetail(athleteObject.id))
  }

  updateBatch = athletes =>
    requestV3.put('athlete.updateBatch', athletes)

  delete(id) {
    return requestV3.delete(`athlete.delete/${id}`);
  }

  export(bySchools = [], selectedStates = [], selectedCounties = [], selectedPositions = []) {
    let data = {
      bySchools: bySchools,
      byState: selectedStates,
      byCounties: selectedCounties,
      byPositions: selectedPositions
    };

    return this._exportService.sendRequest(this._apiExport, data, 'Athlete report');
  }

  getFootballStatsByAthlete(id) {
    return http.get(`/athlete/footballstats/${id}`);
  }

  updateFootballStatas(id, footballstats) {
    return http.post('/athlete/footballstats/update', {
      footballstats: footballstats.map(item => {
        return {
          "catagory": item.catagory,
          "name": item.name,
          "year": item.year,
          "value": item.value
        }
      }),
      athlete_id: id
    });
  }

  async getYearClass() {
    return await http.get('/athlete/yearclass');
  }

  getFields() {
    return requestV3.get('athlete.oldStatFields');
  }

  countAll() {
    return http.get('/count/athletes');
  }

  async getAthletesByCoach(params) {
    return await http.get(`/coach/athletes`, {
      params
    });
  }

  paging(params) {
    return http.post('/athlete/paging', params);
  }

  importAthleteData(input) {
    return requestV2.put('manage/athletes/import', input)
  }

  getAthletesByHighSchool(schoolId) {
    return mobileHttp.get('/school/athletes', {
      params: {
        school_id: schoolId
      }
    })
  }

  prepareStatsField = () =>
    requestV3.get('athlete.preparestats')

  getAllRecommended = params =>
    requestV3.get('athlete.recommended', { params })

  connectAthlete = athlete_id =>
    requestV3.post('follow.createAthleteFollowing', { athlete_id })

  clearAllRecommends = () =>
    requestV3.post('athlete.clearAllRecommends')

  checkAthlete = athlete =>
    requestV3.post("athlete.tool.checkAthlete", athlete)

  updateAthleteImport = athlete =>
    requestV3.post("athlete.tool.update", athlete)

  insertAthleteImport = athlete =>
    requestV3.post("athlete.tool.insert", athlete)

  getAthletesDetail = athleteIds => {
    const ids = athleteIds.join(',');
    const requestUrl = urlcat('athlete.merge.getAthleteData', { ids });
    return requestV3.get(requestUrl);
  }

  mergeAthletes = data => {
    return requestV3.post("athlete.merge.mergeAthlete", data);
  }

  getAllBoardCategories() {
    return mobileHttpV2.get("board/category?scope=personal")
  }

  createBoard(inputBoard) {
    return mobileHttpV2.post('boards', inputBoard)
  }

  addByBoards(boardID, athleteIDs) {
    return mobileHttpV2.post(`board/${boardID}/add-by-boards`, { athlete_ids: athleteIDs })
  }
}

export default new AthleteService();