import { observable, action } from "mobx";

export default class LoginStore {
    @observable isLoading = false;

    @action
    startLoading() {
        this.isLoading = true;
    }

    @action
    endLoading() {
        this.isLoading = false;
    }
}
