import * as icons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import swal from 'sweetalert2'

const renderIndexGrid = (row, pageIndex, pageSize) => {
  return (row.index + 1) + (pageIndex * pageSize)
}

const ToFilters = (filters, mapConfig) => {
  if (mapConfig && typeof (mapConfig) == typeof (() => { })) {
    return filters.map(mapConfig)
  }
  return filters.map(x => ({ field: x.id, value: x.value }))
}
const ToSortParameters = (sorts) => sorts.map(x => ({ field: x.id, desc: x.desc }))

const notify = (message, type = 'success') => {
  const toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  toast({
    type: type,
    title: message
  })
}
const renderLoadingIcon = (size = "3x") => (<FontAwesomeIcon className="fas fa-circle-notch fa-spin" icon={icons.faSpinner} size={size} />)
function onPressEnter(event, callback) {
  if (event.keyCode === 13 || event.which === 13) {
    callback();
  }
}

function generateUUID() { // Public Domain/MIT
  var d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now() //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}


/**
 * Credit: Modified from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
function toDisplayableFileSize (bytes=0, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export {
  renderIndexGrid,
  ToSortParameters,
  ToFilters,
  renderLoadingIcon,
  generateUUID,
  notify,
  onPressEnter,
  toDisplayableFileSize,
}
