import { observable, action } from "mobx";
import userService from "@libs/services/UserService";
import swal from "sweetalert2";

export default class RecoveryStore {
  @observable email = '';

  @action
  request(email, callback = {}) {
    callback.startWaiting && callback.startWaiting();
    userService.recoveryPassword(email).then(data => {
      swal({
        type: 'success',
        title: 'Success',
        text: 'Please check your email'
      })
    }).finally(() => {
      callback.startWaiting && callback.closeWaiting();
      this.email = '';
    });
  }
}