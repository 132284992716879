import Axios from "axios";
import _ from "lodash";
import abp from "./abp";

const requestV3 = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_V3}/api/v3/.team`
});

requestV3.interceptors.request.use(
  (config) => {
    config.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
    config.headers.common['Accept'] = '*';
    if (!!abp.auth.getToken())
      config.headers.common['Authorization'] = "Bearer " + abp.auth.getToken();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

requestV3.interceptors.response.use(
  response => {
    const data = _.get(response, 'data');
    if (_.has(data, 'result'))
      return _.get(data, 'result');

    return data;
  },
  error => {
    if (error.response && error.response.data)
      return Promise.reject(error.response.data);

    return Promise.reject(error);
  }
);

export default requestV3;