import abp from "@libs/common/abp";
import { AppConsts } from "@libs/common/Constant";
import _ from "lodash";
import { action, observable } from "mobx";
import swal from "sweetalert2";
import authorizationService from "@libs/services/AuthorizationService";

export default class AuthorizationStore {
  @observable loginModel = {
    email: '',
    password: '',
    rememberClient: false
  };

  @action
  login(model, callback = {}) {
    callback.startWaiting && callback.startWaiting();
    authorizationService.authorization(model)
      .then(data => {
        abp.auth.setToken(data.authToken, new Date(data.expires))
        if (_.isFunction(callback.redirect))
          callback.redirect()
      })
      .catch(error => swal.fire({
        icon: "error",
        title: 'Sign in Error',
        text: error.message
      }))
      .finally(() => callback.closeWaiting && callback.closeWaiting())
  }

  logout(callback = {}) {
    localStorage.clear();
    sessionStorage.clear();
    abp.auth.clearToken();
    callback.redirect && callback.redirect();
  }

  @action
  init() {
    this.loginModel = {
      email: '',
      password: '',
      rememberClient: false
    }
  }
}