import http from '../common/request';
import requestV3 from '../common/requestV3';

export class StateService {
  async getStates() {
    const { states } = await requestV3.get('state.getAll');
    return states;
  }

  async getStatesAutoComplete(name = '', state_selected_id) {
    const states = await http.get('/autocomplete/states', {
      params: {
        name,
        state_selected_id
      }
    });
    return states;
  }

  async getStatesAll() {
    return await http.get('/states/all');
  }

  getStateCascader() {
    return http.get('state/all')
  }
}

export default new StateService();