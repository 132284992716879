import userFinderService from "@libs/services/UserFinderService";
import UserService from "@libs/services/UserService";
import smsDispatcherService from "@libs/services/smsDispatcherService";

export const actionTypes = {
  SET_USER_COMBOBOX: "SET_USER_COMBOBOX",
  DISPLAY_SMS_RES_MESSAGES: "DISPLAY_SMS_RES_MESSAGES",
  SET_RECENTLY_MESSAGES: "SET_RECENTLY_MESSAGES",
};

export const getUserComboBoxData =
  (textSearch, userGroupId, pageSize = 1000) =>
  async (dispatch) => {
    const userComboBox = await UserService.getUsers({
      pageIndex: 1,
      pageSize: pageSize,
      filtered: {
        name: textSearch,
        status: ["Active"],
        ...(userGroupId && { user_group_id: userGroupId }),
      },
    });

    return dispatch({
      type: actionTypes.SET_USER_COMBOBOX,
      userComboBox,
    });
  };

export const displaySmsResMessages =
  (errorMessages, successMessages, totalErrorCount, totalSuccessCount) =>
  async (dispatch) => {
    return dispatch({
      type: actionTypes.DISPLAY_SMS_RES_MESSAGES,
      payload: {
        errorMessages,
        successMessages,
        totalErrorCount,
        totalSuccessCount,
      },
    });
  };

export const getRecentlyMessages =
  (page = 1, type = "loadmore") =>
  async (dispatch) => {
    const recentlyMessages = await smsDispatcherService.getRecentlyMessages(
      page
    );
    return dispatch({
      type: actionTypes.SET_RECENTLY_MESSAGES,
      recentlyMessages,
      loadmore: type === "loadmore",
    });
  };

export default {
  getRecentlyMessages,
  displaySmsResMessages,
  getUserComboBoxData,
  actionTypes,
};
