import { action, computed, observable, toJS } from "mobx";
import abp from "@libs/common/abp";
import { generateUUID } from "@libs/common/Utilities";
import { AthleteService } from "@libs/services/AthleteService";
import _ from "lodash";

export default class AthleteGridStore {
  @observable _callback = {};
  @observable _columns = [];
  @observable _years = [];
  @observable _fields = [];
  @observable _pageSize = 0;
  @observable _stateSelelected = [];
  @observable _countiesSelelected = [];
  @observable _highSchoolSelected = [];
  @observable name = "";
  @observable yearclass = "";
  @observable _positions = [];
  @observable _dataSource = {
    gridKey: generateUUID(),
    countAll: 0,
    data: [],
    total: 0,
    pageSize: 0,
    pageIndex: 0,
    loading: false,
    isCounting: false,
  };
  @observable gridKey = "".toGuid();
  @observable _filter = {
    stats: {},
  };
  @observable _athleteId = 0;
  @observable _stats = [];
  @observable _enableMultiSelect = false;
  @observable _enableMergeTool = false;
  @observable _athleteSelected = [];

  constructor() {
    this.athleteService = new AthleteService();
  }

  @action
  refresh() {
    this.gridKey = "".toGuid();
  }

  @action
  setCallback(callback) {
    this._callback = callback;
  }

  @action
  setHighSchoolSelected(highSchools) {
    this._highSchoolSelected = highSchools || [];
  }

  @action
  setStateSelected(states) {
    this._stateSelelected = states || [];
  }

  @action
  setCountiesSelected(counties) {
    this._countiesSelelected = counties || [];
  }

  @computed
  get highSchoolSelected() {
    return this._highSchoolSelected;
  }

  @computed
  get countiesSelected() {
    return this._countiesSelelected;
  }

  @computed
  get stateSelected() {
    return this._stateSelelected;
  }

  @computed
  get columns() {
    return toJS(this._columns);
  }

  @action
  setFilter(filter) {
    this._filter = filter;
  }

  @action
  setPositions(positions) {
    this._positions = positions;
  }

  @computed
  get gridFilter() {
    return toJS(this._filter);
  }

  @computed
  get dataSource() {
    return toJS(this._dataSource);
  }

  @action
  setStats(stats) {
    this._stats = stats;
  }

  @computed
  get stats() {
    return toJS(this._stats);
  }

  converFilterFromState(filtered) {
    this._filter = { stats: {} };
    const stats_name = _.map(this.stats, (item) => item.name);
    let statReverse = {};
    this.stats.forEach((item) => (statReverse[item.name] = item.id));
    filtered.forEach((item) => {
      if (stats_name.includes(item.id)) {
        const statId = statReverse[item.id];
        if (statId) {
          const { from, to } = item.value;
          if (from > 0 || to > 0) {
            this._filter.stats[statId] = item.value;
          }
          return;
        }
      }
      this._filter[item.id] = item.value;
    });
  }

  @action
  fetchDataSource(state) {
    this.converFilterFromState(state.filtered);
    this._filter.name = this.name;
    this._filter.yearclass = this.yearclass;
    this._filter.states = _.map(this.stateSelected, "id");
    this._filter.counties = _.map(this.countiesSelected, "id");
    this._filter.schools = _.map(this.highSchoolSelected, "id");
    this._filter.positions = _.map(this._positions, "id");
    this._dataSource.loading = true;

    let sorted = {};
    state.sorted.forEach(
      (item) => (sorted[item.id] = !item.desc ? "desc" : "")
    );
    this._filter.sorted = sorted;

    let { page, pageSize } = state;
    page += 1;
    this._dataSource.isCounting = true;

    this.athleteService
      .getGridData(page, pageSize, this._filter, sorted)
      .then((dataSource) => {
        //prepare athletes
        const athletes = _.map(dataSource.data, (item) => {
          let athleteStat = {};
          //prepare stats
          item.live_user_loading = true;
          item.positions = _.map(item.positions, "abbrev").join("/");
          item.school = [
            _.get(item.highschools, "[0].name"),
            _.get(item.highschools, "[0].state.name"),
            _.get(item.highschools, "[0].county.name"),
          ]
            .filter(Boolean)
            .join(", ");
          for (const stat of _.map(item.stats)) {
            let value = stat.value;
            if (stat.id === 12 || stat.id === 13) {
              if (stat.value) {
                const valuesToShow = [stat.value, stat.display_value]
                  .filter(Boolean)
                  .join(" - ");
                value = valuesToShow;
              }
            }
            athleteStat[stat.name] = value || "";
          }

          return { ...item, ...athleteStat };
        });

        //prepare live users
        this.athleteService
          .getLiveUsers(_.map(athletes, "id"))
          .then((res) => {
            const athlete_map = _.keyBy(athletes, "id");

            for (let user_item in res)
              if (athlete_map[user_item]) {
                athlete_map[user_item].find_live_user = true;
                athlete_map[user_item].live_user = res[user_item].live_user;
                athlete_map[user_item].live_user_loading = false;
              }

            return athlete_map;
          })
          .then((athlete_map) => {
            for (let athlete_item of athletes) {
              athlete_item.live_user_loading = false;
              if (athlete_map[athlete_item.id])
                athlete_item.live_user = athlete_map[athlete_item.id].live_user;
            }
            return athletes;
          })
          .then((data) => {
            const _dataSource = {
              data,
              pageSize: dataSource.pageSize,
              pageIndex: dataSource.page,
              total_items: dataSource.count_all,
              total_pages: dataSource.total_page,
            };

            this._dataSource = _dataSource;
          });

        return { ...toJS(this._filter), pageSize };
      })
      .finally(() => {
        this._dataSource.loading = false;
        this._dataSource.isCounting = false;
      });
  }

  @action
  setAthleteId(id) {
    this._athleteId = id;
    abp.localStorage.setValue("_athleteId", id);
  }

  @computed
  get athleteSelectedId() {
    const _athleteId = abp.localStorage.getValue("_athleteId");
    if (_athleteId) {
      return parseInt(_athleteId);
    }
    return _athleteId;
  }

  @action
  toggleMultiSelect() {
    this._enableMultiSelect = !this._enableMultiSelect;
  }

  @computed
  get isEnableMultiSelect() {
    return this._enableMultiSelect;
  }

  @action
  toggleMergeTool() {
    this._enableMergeTool = !this._enableMergeTool;
  }

  @computed
  get isEnableMergeTool() {
    return this._enableMergeTool;
  }

  @action
  setAthleteSelected(athletes) {
    this._athleteSelected = athletes || [];
  }

  @computed
  get athleteSelected() {
    return this._athleteSelected;
  }
}
