import React, { Component } from 'react'
import { generateUUID } from './Utilities';

Array.prototype.any = function (predicate) {
  if (predicate && typeof (predicate) === typeof (() => { })) {
    return this.filter(predicate).some(x => x)
  }
  return this.some(x => x)
}

String.prototype.isNullOrEmpty = function () {
  return this === null || this === ''
}

String.prototype.toGuid = function () {
  return generateUUID();
}

String.prototype.isEmailAddress = function () {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(this).toLowerCase());
}

String.prototype.buildQuery = function (data, prefixQuery = '?') {

  // If the data is already a string, return it as-is
  if (typeof (data) === 'string') return data;

  // Create a query array to hold the key/value pairs
  var query = [];

  // Loop through the data object
  for (var key in data) {
    if (data.hasOwnProperty(key)) {

      // Encode each key and value, concatenate them into a string, and push them to the array
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
  }

  // Join each item in the array with a `&` and return the resulting string
  return `${this}${prefixQuery}${query.join('&')}`;

};

String.prototype.buildParams = function (data, prefixParams = ':') {
  let str = this || '';
  // If the data is already a string, return it as-is
  if (typeof (data) === 'string')
    return data;

  // Create a query array to hold the key/value pairs


  // Loop through the data object
  for (var key in data) {
    str = str.replace(`${prefixParams}${key}`, data[key])
  }

  // Join each item in the array with a `&` and return the resulting string
  return str

};