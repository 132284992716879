import http from "../common/request";
import requestV3 from "../common/requestV3";
import { BaseService } from "./BaseService";

export class PositionService extends BaseService {
  constructor() {
    super()
    this._apiFootballPositionAddUpdate = `/athlete/footballpositions`
  }

  async getPositions(name) {
    return await requestV3.get('position.getall', {
      params: {
        name
      }
    });
  }

  addUpdateFootballPositions(positions) {
    return http.post('/athlete/footballpositions', { positions });
  }

  async fetchAutoCompletePositions(params) {
    return await requestV3.get('position.getall', { params: params });
  }
}

export default new PositionService();