import { fromJS } from 'immutable';
import { setSchoolLocations, SET_SCHOOL_INSTANCE } from './action';

// The initial state of the App
const initialState = fromJS({
	schoolLocations: [],
	school: null
});

export default function athleteReducer(state = initialState, action) {
	switch (action.type) {
		case setSchoolLocations:
			return state.set('schoolLocations', action.data);
		default:
			return state
	}
}