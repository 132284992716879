import http from "../common/request";
import requestV3 from "../common/requestV3";

class SessionService {
  getCurrentInfomations() {
    return requestV3.get('account.profile')
  }

  async forgotPassword(email) {
    return await requestV3.post("account.recovery_password", { email });
  }
}

export default new SessionService();