import { observable, toJS, computed, action } from "mobx";
import Routes from "@components/Route";
import abp from "@libs/common/abp";


export default class LeftSideBarStore {
  @observable _breadcrumbs = {};
  @observable _menus = [];

  @action
  fetch() {
    this._menus = [];
    Routes.forEach(item => {
      this._breadcrumbs[item.path] = {
        title: item.name,
        id: "".toGuid()
      };

      if (item.isVisible && abp.auth.isAllowAccess(item.permissions)) {
        this._menus.push({
          id: "".toGuid(),
          title: item.name,
          to: item.path,
          icon: item.icon,
        });
      }
    });
  }

  @computed
  get breadcrumbs() {
    return toJS(this._breadcrumbs);
  }

  @computed
  get menus() {
    return toJS(this._menus);
  }
}