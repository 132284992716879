import http from "../common/request";

class SmsDispatcherService {
  send(smsPayload) {
    return http.post('/send-sms', smsPayload)
  }

  getRecentlyMessages(page) {
    return http.get('/send-sms/logs/' + page)
  }
}

export default new SmsDispatcherService();