import Axios from "axios";
import _ from "lodash";
import swal from 'sweetalert2';
import abp from "./abp";
import appConfig from './appConfig.json';
import { AppConsts } from "./Constant";

function handleError(e) {
  return swal({
    type: AppConsts.swalType.error,
    title: e.title || 'Oops...',
    text: e.message || ''
  });
}
const requestV2 = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_V2}/api/team`
});

requestV2.interceptors.request.use(
  (config) => {
    config.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
    config.headers.common['Accept'] = '*';
    if (!!abp.auth.getToken())
      config.headers.common['Authorization'] = abp.auth.getToken();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

requestV2.interceptors.response.use(
  response => {
    const data = _.get(response, 'data');
    if (_.has(data, 'result'))
      return _.get(data, 'result');

    return data;
  },
  error => {
    if (!error.response) {
      handleError({ message: error.message });
    } else if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      abp.auth.clearToken();
      window.location.replace('/login');
    }
    else if (error.response.status === 400 && error.response.data) {
      const { data } = error.response
      if (data.result)
        return Promise.reject(data.result);

      return Promise.reject(data);
    } else {
      if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        handleError({
          title: error.response.data.error.message,
          message: error.response.data.error.details
        });
      } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        handleError({
          title: 'Login Failed',
          message: error.response.data.error.message
        });
      }
      setTimeout(() => { }, 1000);
    }
    return Promise.reject(error);
  }
);

export default requestV2;