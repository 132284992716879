import { Avatar, Button, Drawer, Divider } from "antd"
import _ from "lodash"

export default ({ visible, onLogoutClick, currentLogin, onClose }) => {
  return (
    <Drawer bodyStyle={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', justifyContent: 'center', alignItems: 'center', height: '100%' }} placement="right" closable={false} onClose={onClose} visible={visible}>
      <div className="flex-fill d-flex justify-content-start align-items-center flex-column">
        <Avatar className="drawer-avatar" src={_.get(currentLogin, "avatar")} style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', fontSize: 80, width: 100, height: 100 }} size="large">
          {_.get(currentLogin, "name[0]")}
        </Avatar>
        <h4 className="mt-3 text-center">{_.get(currentLogin, "name")}</h4>
        <Button type="danger" block onClick={onLogoutClick}>Logout</Button>
      </div>
      <Divider />
      <div className="text-center d-flex flex-column justify-content-between align-items-center">
        <div>Version 2.0.39</div>
      </div>
    </Drawer>
  )
}