import { observable, action, computed, toJS } from "mobx";
import userService from "@libs/services/UserService";
import { notification, Modal } from "antd";
import AuthorizationService from "@libs/services/AuthorizationService";
import _ from "lodash";
import abp from '@libs/common/abp';

export default class UserInfomationStore {
  @observable isUserModalVisible = false;
  @observable _currentUserInfo = {};
  @observable roles = [];

  @action
  async fetchRoles() {
    const roles = await AuthorizationService.fetchRoles();
    this.roles = roles;
    console.log(abp.auth.grantedPermissions);
  }

  @action
  async fetchUserById(data) {
    const { id } = data;
    const user = await userService.getUserById(id);
    let firstName = user.first_name;
    let lastName = user.last_name;

    const userDetail = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
      email: user.email,
      user_roles: user.user_roles,
      phone: user.phone || user.phone_number,
      hudl: user.hudl,
      twitter: user.twitter,
      newPassword: '',
      newConfirmPassword: '',
      isResetPassword: false,
      user_group_id: user.user_group_id,
      profile_visible: user.profile_visible,
      default_password: user.default_password,
      title: user.title,
      ...data
    };
    this._currentUserInfo = userDetail;

    return user;
  }

  @computed
  get currentUserInfo() {
    return toJS(this._currentUserInfo);
  }

  @action
  setCurrentUserInfo(model) {
    this._currentUserInfo = { ...model };
  }

  @action
  async saveCurrentUserInfo(model) {
    const data = await userService.updateUserInfo({ ...model });
    if (data.errors && _.some(data.errors))
      return Modal.error({
        title: 'Error',
        content: data.errors.join('</br>')
      });

    notification.success({ message: 'Update successful' });
    this.isUserModalVisible = false;
  }

  @action
  async deleteUser(userId) {
    await userService.deleteUserById(userId);
    notification.success({ message: 'Delete successful' });
  }
}