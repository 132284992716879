import http from "../common/request";

class PostManagerService {
  async fetch(params) {
    return await http.get('/posts', { params: { ...params } });
  }

  async getById(id) {
    return await http.get('/post/' + id);
  }

  async hidden(id) {
    return await http.delete('/post/' + id);
  }

  async searchAuthor(name) {
    return await http.get('/post/authors', { params: { name } });
  }
}

export default new PostManagerService()