import { action, computed, observable, toJS } from "mobx";
import authorizationService from "@libs/services/AuthorizationService";

export default class RoleStore {
    @observable _roles = [];

    @computed
    get roles() {
        return toJS(this._roles);
    }

    @action
    async fetchRoles() {
        const roles = await authorizationService.fetchRoles();
        this._roles = roles;
    }
}