import React, { ReactDOM } from 'react'
const authKey = 'authToken';
const user = 'user';
const statusFail = 'fail';
const statusSuccess = 'success';

const fail = {
  message: 'Oops! Something went wrong'
}

const success = {
  message: 'Successful',
  updateMessage: 'Update successful'
}

const deleteMessage = {
  title: 'Are you sure?',
  message: 'This record will be deleted. Are you sure?'
}

const hudlLogo = (props) => {
  return (
    <svg className="hudl-logo" width="80%" viewBox="0 0 112 48">
      <g stroke="none">
        <path d="M25.6188422,37.8744664 C23.339885,37.8769434 21.3564696,
                    36.3165684 20.8224714,34.1011082 C20.8157345,34.0722752 20.7953796,
                    34.0485284 20.7679156,34.0374612 C20.1189743,33.7764285 19.4922577,
                    33.4632298 18.8939212,33.1009409 C18.8764629,33.090487 18.8630159,
                    33.0744791 18.8557321,33.0554788 C17.9821155,30.7902887 17.7582167,
                    28.3262006 18.209245,25.9406528 C18.2155336,25.9066633 18.2405517,
                    25.8791792 18.2738027,25.8697318 L18.291988,25.8697318 C18.3195625,
                    25.8666869 18.3470185,25.8763968 18.3665477,25.8960998 C19.5712055,
                    27.1982716 21.0308048,28.2387839 22.6546396,28.9529746 C22.6664156,
                    28.9579966 22.6791193,28.9604753 22.6919195,28.9602486 C22.7111801,
                    28.9603172 22.729965,28.9542679 22.7455661,28.9429729 C24.8897848,
                    27.4028393 27.8675735,27.8306931 29.4914002,29.9122259 C29.5087609,
                    29.934779 29.5356806,29.947902 29.5641413,29.9476864 L29.5750525,
                    29.9476864 C30.0242292,29.8867671 30.4843171,29.800389 30.9316753,
                    29.6930984 C33.6145995,29.04157 36.0241759,27.5616278 37.8184454,
                    25.4633002 C37.8359274,25.4428592 37.8615627,25.4312071 37.8884588,
                    25.4314767 C37.9036913,25.4314005 37.9186989,25.4351523 37.9321035,
                    25.4423876 C37.9700059,25.4626063 37.9885866,25.5065921 37.9766575,
                    25.5478598 C36.8957541,29.3696116 34.1259963,32.4869803 30.4579485,
                    34.0101839 C30.4304844,34.0212511 30.4101295,34.0449979 30.4033926,
                    34.0738309 C29.8844662,36.2979157 27.9027135,37.8721325 25.6188422,
                    37.8744664 Z M16.4234493,31.1169728 C16.3988342,31.1171166 16.3752126,
                    31.1072745 16.3579822,31.0896955 C13.5722699,28.2584122 12.2562061,
                    24.2958749 12.7945742,20.3606288 C12.7986473,20.3312413 12.7881261,
                    20.301715 12.766387,20.2815247 C11.1025729,18.7217873 10.7276666,
                    16.2236208 11.8603538,14.2442251 C12.9930409,12.2648295 15.3368292,
                    11.3223316 17.5245687,11.966498 C17.553165,11.9749384 17.5840824,
                    11.968823 17.6073118,11.9501316 C18.1577143,11.512874 18.7434746,
                    11.1220723 19.3585554,10.7817544 C19.3722302,10.7743696 19.3875696,
                    10.7706131 19.4031094,10.7708435 C21.8131339,11.1438279 24.0702365,
                    12.185252 25.9179903,13.7768007 C25.9441002,13.7991701 25.9553591,
                    13.8343535 25.9470867,13.867725 C25.939131,13.9018803 25.9122412,
                    13.9284157 25.8779826,13.9359182 C24.1433542,14.3270196 22.5080598,
                    15.0717526 21.0743377,16.1235567 C21.0477504,16.1426897 21.0335094,
                    16.1746442 21.0370579,16.207207 C21.0544884,16.3727253 21.0632899,
                    16.5390388 21.0634266,16.7054722 C21.0467424,19.1516549 19.2446946,
                    21.2183764 16.8235257,21.5681034 C16.790857,21.572331 16.7630297,
                    21.5938966 16.7507845,21.6244765 C16.5780243,22.0481837 16.4243585,
                    22.4864388 16.2961522,22.9274216 C15.5200055,25.5727542 15.5957139,
                    28.3952116 16.5125572,30.9951343 C16.5228786,31.0236069 16.5183455,
                    31.0553495 16.500467,31.0797951 C16.4825884,31.1042407 16.4537115,
                    31.1181801 16.4234493,31.1169728 L16.4234493,31.1169728 Z M32.5601682,
                    26.5298422 C32.5302641,26.5313292 32.5015446,26.5179995 32.4833771,
                    26.4942006 C32.4652096,26.4704018 32.4599231,26.4391847 32.4692418,
                    26.4107314 C32.9887605,24.7374676 33.1629213,22.9761041 32.9811577,
                    21.233502 C32.9779292,21.2013202 32.9578477,21.173276 32.9284204,
                    21.1598534 C31.1953896,20.3516184 30.0836195,18.616732 30.0733295,
                    16.7045629 C30.0696535,16.0608494 30.1932577,15.422758 30.4370354,
                    14.8269763 C30.4497842,14.7967547 30.4452672,14.7620094 30.4252149,
                    14.736052 C30.1311066,14.3506924 29.8169784,13.9810315 29.4841261,
                    13.6285941 C27.5827746,11.6318387 25.1016753,10.2834888 22.3918621,
                    9.77431322 C22.3477559,9.76670946 22.3157425,9.72814002 22.3163932,
                    9.68338893 C22.3151463,9.64059112 22.3439272,9.60272231 22.3854973,
                    9.59246463 C26.2661438,8.59183447 30.3914602,9.46566738 33.5330813,
                    11.9537686 C33.5493223,11.9667984 33.5695433,11.9738595 33.590365,
                    11.9737719 C33.5994059,11.9751435 33.608602,11.9751435 33.617643,
                    11.9737719 C34.0635338,11.8392297 34.5267034,11.7706136 34.992451,
                    11.7701015 C37.0140355,11.7645997 38.8333967,12.9957848 39.5798692,
                    14.8744664 C40.3263418,16.753148 39.8480988,18.8972162 38.3740061,
                    20.2806154 C38.352267,20.3008057 38.3417459,20.3303321 38.3458189,
                    20.3597196 C38.4236171,20.930473 38.4631069,21.5057954 38.4640233,
                    22.0818257 C38.4640233,22.135471 38.4640233,22.1882071 38.4567492,
                    22.2409432 L38.4503843,22.3418692 C38.4500624,22.3620658 38.4430242,
                    22.3815802 38.4303805,22.397333 C36.9066411,24.290342 34.8841259,
                    25.7197998 32.5910832,26.5243867 L32.5601682,26.5298422 Z" id="mark" fill="#FFF"></path>
        <path d="M52.2433929,18.4921346 C51.3104954,18.4947517 50.38597,
                18.6682501 49.5155991,19.0040383 L49.5155991,11.7555536 L44,
                11.7555536 L44,34.0692847 L49.5146898,34.0692847 L49.5146898,
                22.9837947 C49.8837142,22.8294344 50.2803822,22.7520829 50.6803671,
                22.756484 C51.8460443,22.756484 52.357051,23.3247608 52.357051,
                24.3767549 L52.357051,34.0692847 L57.8717408,34.0692847 L57.8717408,
                23.7793823 C57.8717408,20.3715397 55.9395535,18.4921346 52.2433929,
                18.4921346 L52.2433929,18.4921346 Z M68.4201195,29.834031 C68.1928033,
                29.9194999 67.7663582,29.9758729 67.2544423,29.9758729 C66.2315196,
                29.9758729 65.6914164,29.549438 65.6914164,28.5829127 L65.6914164,
                18.7485411 L60.1767266,18.7485411 L60.1767266,28.4410708 C60.1767266,
                32.7336068 62.5362682,34.4420743 67.2544423,34.4420743 C69.8131129,
                34.4420743 72.4563451,33.9874528 73.9348093,33.3055206 L73.9348093,
                18.7485411 L68.4201195,18.7485411 L68.4201195,29.834031 L68.4201195,
                29.834031 Z M85.0823934,18.8321914 C84.5535157,18.6909519 84.0094103,
                18.6146202 83.4620839,18.6048807 C78.6593482,18.6057899 76.2161542,
                21.6444798 76.2161542,26.394365 C76.2161542,31.6525169 79.0303282,
                34.4384373 84.2604182,34.4384373 C86.9609341,34.4384373 89.2913792,
                33.9556293 90.5989017,33.3018836 L90.5989017,11.7528259 L85.0833026,
                11.7528259 L85.0833026,18.8303729 L85.0823934,18.8321914 Z M85.0823934,
                30.0595233 C84.8832644,30.1449921 84.514103,30.1731787 84.1440323,
                30.1731787 C82.5810065,30.1731787 81.7281163,28.9511561 81.7281163,
                26.3361734 C81.7281163,24.1194391 82.5528193,22.6691966 84.0876579,
                22.6691966 C84.4859158,22.6691966 84.9123609,22.7546655 85.0823934,
                22.8401343 L85.0823934,30.0604325 L85.0823934,30.0595233 Z M93.5203689,
                11.7555536 L93.5203689,34.0692847 L99.0386958,34.0692847 L99.0386958,
                11.7555536 L93.5203689,11.7555536 L93.5203689,11.7555536 Z" id="hudl" fill="#FFFFFF">
        </path>
      </g>
    </svg>
  )
}


const AppConsts = {
  appBaseUrl: process.env.NODE_ENV === 'development' ? `http://localhost:5000/api` : `http://18.218.144.171/api`,
  importBaseUrl: process.env.NODE_ENV === 'development' ? `https://api.rosterspot.com` : `https://api.rosterspot.com`,
  failReponse: 'fail',
  swalType: {
    error: 'error',
    success: 'success'
  },
  homePageUrl: '/data',
  registerUrl: '/data/register',
  commonModalProps: {
    destroyOnClose: true,
    maskClosable: false,
    closable: false,

  },
  appName: 'Rosterspot'
}


export {
  AppConsts,
  authKey,
  user,
  statusFail,
  statusSuccess,
  deleteMessage,
  fail,
  success,
  hudlLogo
}