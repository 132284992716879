import { AthleteDetailStore } from "./athleteStores/athleteDetailStore";
import AthleteGridStore from "./athleteStores/athleteGridStore";
import AuthorizationStore from "./authorization/authorizationStore";
import { ForgotPasswordStore } from "./authorization/forgotPassword";
import RecoveryStore from "./authorization/recoveryStore";
import PositionAutoComplete from "./autoComplete/positionAutoComplete";
import CoachHighSchoolModalStore from "./coachHighSchool/coachHighSchoolModalStore";
import CountyStore from "./countyStores/countyStore";
import { HighSchoolStore } from "./highSchool/highSchoolStore";
import LeftSideBarStore from "./leftSideBar/leftSideBarStore";
import LoginStore from "./login/loginStore";
import PositionModalStore from "./positionModal/positionModaStorel";
import PostStore from "./post/postStore";
import RegisterStore from "./register/registerStore";
import RoleStore from "./roleStore/roleStore";
import SessionStore from "./sessionStores/sessionStore";
import StateStore from "./stateStores/stateStore";
import StatModalStore from "./statModal/statModalStore";
import UserConvertStore from "./userInfomation/userConvertStore";
import UserInfomationStore from "./userInfomation/userInfomationStore";
import UserStore from "./userStore/userStore";

export default function initializeStores() {
  return {
    athleteGridStore: new AthleteGridStore(),
    stateStore: new StateStore(),
    athleteDetailStore: new AthleteDetailStore(),
    authorizationStore: new AuthorizationStore(),
    loginStore: new LoginStore(),
    sessionStore: new SessionStore(),
    recoveryStore: new RecoveryStore(),
    highSchoolStore: new HighSchoolStore(),
    registerStore: new RegisterStore(),
    countyStore: new CountyStore(),
    forgotPasswordStore: new ForgotPasswordStore(),
    positionModalStore: new PositionModalStore(),
    statModalStore: new StatModalStore(),
    coachHighSchoolModalStore: new CoachHighSchoolModalStore(),
    userInfomationStore: new UserInfomationStore(),
    positionAutoComplete: new PositionAutoComplete(),
    roleStore: new RoleStore(),
    userStore: new UserStore(),
    postStore: new PostStore(),
    userConvertStore: new UserConvertStore(),
    leftSite: new LeftSideBarStore()
  };
}