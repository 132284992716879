import { statusSuccess } from '../common/Constant'


export class BaseService {
  domain = ''
  rootUrl = ''
  mobieApiPrefix = ''

  constructor() {
    this.configApi();
  }

  configApi() {
    let hostName = `${window.location.protocol}//${window.location.hostname}`;
    this.socketUrl = process.env.NODE_ENV === 'development' ? `${hostName}:5000` : hostName;
    this.domain = process.env.NODE_ENV === 'development' ? `${hostName}:5000/api` : `${hostName}/api`;
    this.mobieApiPrefix = process.env.NODE_ENV === 'development' ? `${hostName}:5000/mobile/v1` : `${hostName}/mobile/v1`;
    this.rootUrl = process.env.NODE_ENV === 'development' ? `${hostName}:5000/` : hostName;
  }

  getStatusSuccess() {
    return statusSuccess;
  }
}