import { observable, action, computed, toJS } from "mobx";
import schoolService from "@libs/services/SchoolService";

export class HighSchoolStore {
    @observable _highSchools = [];

    @computed
    get highSchools() {
        return toJS(this._highSchools || []);
    }

    @action
    async fetchHighSchool(params) {
        this._highSchools = await schoolService.getHighSchoolAutoComplete(params);
    }
}