import { observable, action, computed, toJS } from "mobx";
import { UserService } from "@libs/services/UserService";

export default class UserStore {
  userService = new UserService();
  @observable gridLoading = false;
  @observable _dataSource = {};
  @observable _gridState = null;
  @observable _filterd = [];
  @observable _sorted = [];
  @observable _roleSelected = [];

  @observable ui = {
    tblUserKey: ''.toGuid()
  }

  @action
  setFiltered = (value) => {
    this._filterd = value;
    this._filterd['roles'] = (this._filterd['roles'] || [])
  }

  @action
  setSorted = (value) => {
    this._sorted = value;
  }

  @action
  setGridState(state) {
    this._gridState = state;
  }


  @action
  fetchUsers = (state, instance) => {
    this.gridLoading = true;
    let { sorted } = state;
    let fieldSorted = {};

    for (const key in sorted) {
      const element = sorted[key];

      Object.keys(element).forEach(key => fieldSorted = {
        ...({
          [element.id]: !element.desc ? 'desc' : 'asc'
        })
      })

    }

    this.userService.getUsers({
      pageIndex: state.page + 1,
      pageSize: state.pageSize,
      filtered: this.filtered,
      sorted: fieldSorted
    }).then(data => {
      this._dataSource = {
        data: data.users || [],
        pages: data.total_page,
        countAll: data.countAll,
        pageIndex: data.page_index,
        pageSize: data.page_size
      }
    }).finally(() => this.gridLoading = false);
  }

  @action
  lock = async id => {
    await this.userService.lockUser(id);
  }

  @action
  unlock = async id => {
    await this.userService.unlock(id);
  }

  @computed
  get dataSource() {
    return toJS(this._dataSource);
  }

  @computed
  get filtered() {
    return toJS(this._filterd);
  }

  @computed
  get sorted() {
    return toJS(this._sorted);
  }
}