import { observable } from "mobx";
import sessionService from "@libs/services/sessionService";

export class ForgotPasswordStore {
  @observable isLoading = false;

  sendRequest(email, callback = {}) {
    this.isLoading = true;
    sessionService.forgotPassword(email)
      .then(data => callback && callback.redirect())
      .finally(() => this.isLoading = false);
  }
}