import React from 'react';
import { useSelector } from 'react-redux';
import './main.css';

export default () => {
  const { layoutLoading } = useSelector(state => state.layoutState)

  if (!layoutLoading)
    return null

  return (
    <div id="loader-wrapper" style={{ backgroundColor: "#fff" }}>
      <div id="loader"></div>
    </div >
  )
}