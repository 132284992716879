import _ from 'lodash'
import smsDispatcherActions from "@stores/actions/smsDispatcherActions";


const initialState = {
  userComboBox: [],
  errorMessages: [],
  successMessages: [],
  totalErrorCount: 0,
  totalSuccessCount: 0,
  recentlyMessages: {
    data: []
  }
};


export default function reducer(state = initialState, action) {
  switch (action.type) {
    case smsDispatcherActions.actionTypes.SET_USER_COMBOBOX:
      return {
        ...state,
        userComboBox: action.userComboBox
      }
    case smsDispatcherActions.actionTypes.DISPLAY_SMS_RES_MESSAGES:
      return {
        ...state,
        ...action.payload
      }
    case smsDispatcherActions.actionTypes.SET_RECENTLY_MESSAGES:
      let recentlyMessages = action.loadmore ? { ...action.recentlyMessages, data: _.concat(state.recentlyMessages.data, action.recentlyMessages.data) } : action.recentlyMessages;
      return {
        ...state,
        recentlyMessages
      }
    default:
      return state;
  }
}