import React from 'react';
import { Spin } from 'antd';


export default class LoadingIndicator extends React.Component {
  render() {
    return (
      <Spin />
      // <div id="loader-wrapper" style={{ "backgroundColor": "#fff" }}>
      // 	<div id="loader"></div>
      // </div>
    );
  }
}