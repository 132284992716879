import LoadingScreen from '@components/LoadingScreen'
import PrivateRoute from '@components/PrivateRoute/PrivateRoute'
import Routes, { routePath } from '@components/Route'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import _ from 'lodash'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import '@libs/common/ExtensionsMethod'

export default props => {
  return (
    <div className="d-flex flex-column justify-content-between align-items-center flex-fill w-100">
      <Helmet title="Rosterspot .team" />
      <div id="main-wrapper" className="d-flex flex-column align-items-center flex-fill w-100">
        <BrowserRouter>
          <Switch key="intertor">
            <Route exact key="/app" path="/" render={() => <Redirect to={routePath.dashBoard} />} />
            <Route exact key="/app/login" path="/login" render={() => <Redirect to={routePath.login} />} />
            {_.map(Routes, (item, idx) => {
              if (item.authRequired)
                return (
                  <PrivateRoute
                    key={idx}
                    exact
                    permissions={item.permissions}
                    path={item.path}
                    component={item.component}
                  />
                )
              return <Route exact key={idx} path={item.path} component={item.component} />
            })}
            <Route key="/404" path="" component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
        <LoadingScreen />
      </div>
    </div>
  )
}