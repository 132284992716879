import Axios from "axios";
import { AppConsts } from "../common/Constant";
import abp from "../common/abp";

export default class ExportExcelService {
  async sendRequest(api, data, fileName = 'file') {
    const config = {
      method: 'get',
      url: AppConsts.appBaseUrl + api,
      responseType: 'blob', // important
      headers: {
        'Content-Type': 'application/json',
        'Authorization': abp.auth.getToken()
      },
    };

    return await Axios(config)
      .then(response => {
        let link = document.createElement('a');
        let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        let url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = `${fileName}-${new Date().toISOString().substring(0, 10)}.xlsx`;
        link.click()
        return url
      });
  }
}
