import { Modal } from "antd";
import Axios from "axios";
import swal from 'sweetalert2';
import abp from "./abp";
import { AppConsts } from "./Constant";
import { notify } from "./Utilities";

function handleError(e) {
  return swal({
    type: AppConsts.swalType.error,
    title: e.title || 'Oops...',
    text: e.message || ''
  });
}

const http = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_API_V2}/api`
});

http.interceptors.request.use(
  (config) => {
    if (!!abp.auth.getToken()) {
      config.headers.common['Authorization'] = abp.auth.getToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    const { data } = response;
    if (data.success && data.success.message && data.success.message && data.success.details)
      return notify(data.success.details);

    return data;
  },
  error => {
    if (!error.response) {
      handleError({ message: error.message });
    } else if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      abp.auth.clearToken();
      window.location.replace('/login');
    }
    else if (error.response.status === 400 && error.response.data) {
      Modal.error({ content: error.response.data.message, title: 'Oops...' })
    } else {
      if (!!error.response && !!error.response.data.error && !!error.response.data.error.message && error.response.data.error.details) {
        handleError({
          title: error.response.data.error.message,
          message: error.response.data.error.details
        });
      } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
        handleError({
          title: 'Login Failed',
          message: error.response.data.error.message
        });
      }
      setTimeout(() => { }, 1000);
    }
    return Promise.reject(error);
  }
);

export default http;