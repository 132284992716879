import requestV3 from '@libs/common/requestV3';
import _ from 'lodash';
import urlcat from 'urlcat';
import http from '../common/request';
import { BaseService } from './BaseService';
import ExportExcelService from './ExportExcelService';
import { UserService } from './UserService';

export class SchoolService extends BaseService {
  _apiSchools = `/simple/schools/query`;
  _apiSchoolByQuery = `/high_schools`
  _apiDetailSchool = `/high_school/`
  _apiAddUpdateSchool = `/school/addupdate`;
  _apiDelete = `/schools/delete`
  _apiExport = `/schools/export`
  headers = [
    { label: 'School Name', key: 'name' },
    { label: 'Address', key: 'address' },
    { label: 'City', key: 'city' },
    { label: 'Phone', key: 'phone' },
    { label: 'Fax', key: 'fax' },
    { label: 'County', key: 'county' },
    { label: 'State', key: 'state' }
  ]

  constructor() {
    super();
    this._exportService = new ExportExcelService()
    this.userService = new UserService()
  }

  getComboBox(state_ids, county_ids) {
    return requestV3.post("highschool.combobox", { state_ids, county_ids })
  }

  async getSchools(states = [], counties = []) {
    const { schools } = await http.post(this._apiSchools, { states, counties });
    return schools;
  }

  async getHighSchoolAutoComplete(params) {
    const results = await requestV3.get('highschool.autocomplete', {
      params: params
    });
    return results || [];
  }

  insertBatch = highschools =>
    requestV3.post("highschool.insertbatch", { highschools })

  updateBatch = highschools =>
    requestV3.post("highschool.updatebatch", { highschools })

  getSchoolCoordinatesByCounty(arrayCountyName) {
    return new Promise((resolve, reject) => {
      return resolve(this.getSchoolsInfo()
        .filter(item => arrayCountyName.includes(item.county))
        .map(this.schoolPositionInfo));
    });
  }

  schoolPositionInfo(item) {
    return {
      address: item.address,
      city: item.city,
      coordinates: item.coordinates,
      county: item.county,
      id: item.id,
      name: item.name,
      state: item.state,
      isActive: false
    }
  }

  downloadTemplate = () =>
    window.open(urlcat(requestV3.defaults.baseURL, '/highschool.tool.template', { ts: new Date().getTime() }))

  export = () =>
    window.open(urlcat(requestV3.defaults.baseURL, '/highschool.tool.export', { ts: new Date().getTime() }))

  import = files => {
    const formData = new FormData()
    formData.append('file', files)
    return requestV3.post('highschool.tool.import', formData)
  }

  async getSchoolById(schoolId) {
    return requestV3.get('/highschool.getbyid/' + schoolId);
  }

  findDuplicates = (schoolId) =>
    requestV3.get(`/highschool.duplicates/${schoolId}`)

  merge = (main_id, merge_ids, ets_code) =>
    requestV3.post("/highschool.tool.merge", { main_id, merge_ids, ets_code })

  async getSchoolsByQuery(pageIndex, pageSize, gridFilter) {
    return requestV3.get(`/highschool.getall`, {
      params: {
        pageIndex: pageIndex,
        pageSize: pageSize,
        gridFilter
      }
    });
  }

  async saveSchoolEntity(school) {
    school.longitude = _.get(school, 'geom_point.coordinates[0]', null)
    school.latitude = _.get(school, 'geom_point.coordinates[1]', null)

    if (school.id === null) {
      const highSchool = await requestV3.post('highschool.create', school);
      return highSchool;
    }

    const highSchool = await requestV3.put('/highschool.update', school);
    return highSchool;
  }

  precheckDelete = (id) =>
    requestV3.get(`highschool/${id}/delete.precheck`);

  async deleteSchoolRecord(id) {
    return requestV3.delete('highschool.delete/' + id);
  }

  exportExcel(counties = [], states = []) {
    return this._exportService.sendRequest(this._apiExport, {
      counties,
      states
    }, "School Report");
  }

  async countAll() {
    return await http.get('/count/schools');
  }
}
export default new SchoolService();;