import { computed, action, observable, configure, toJS } from "mobx";
import stateService from "@libs/services/StateService";

export default class StateStore {
  @observable _states = [];

  @computed
  get states() {
    return toJS(this._states);
  }

  @action
  async fetchStates(name) {
    this._states = await stateService.getStatesAutoComplete(name);
  }

  @action
  async fetchAll() {
    this._states = await stateService.getStatesAll();
  }
}