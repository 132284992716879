import { routePath } from '@components/Route';
import Container from '@containers/Container';
import authorizationService from '@libs/services/AuthorizationService';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';


export default function PrivateRoute({ component: Component, ...rest }) {
  function render(props) {
    if (authorizationService.isAuthorized()) {
      return (
        <Container {...props} {...rest} >
          <Component {...props} />
        </Container>
      )
    }
    return (<Redirect to={routePath.login} />);
  }
  return (<Route {...rest} render={render} />);
}
