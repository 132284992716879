import sessionService from '@libs/services/sessionService';
import { Avatar, Breadcrumb, Icon, Layout, Menu} from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Stores from '../../mobxStores/storeRegister';
import ProfileDrawer from './components/ProfileDrawer';
import rslogo from '@images/rs-logo.png'
import { withRouter } from 'react-router'
import Routes, { routePath } from '@components/Route';
const { Header, Sider, Content, Footer } = Layout;

@inject(Stores.SessionStore, Stores.AuthorizationStore,)
@observer
class Container extends Component {
  state = {
    collapsed: false,
    profileToggle: false
  }

  async componentDidMount() {
    this.props.sessionStore.setCurrentLogin(await sessionService.getCurrentInfomations());
  }

  render() {
    const { sessionStore: { currentLogin }, authorizationStore } = this.props
    const routes = _.filter(Routes, item => item.isVisible)
    const menuIndex = _.toString(_.findIndex(routes, item => item.path === window.location.pathname))
    return (
      <Layout className="w-100 d-flex flex-column flex-fill" key="1">
        <Layout key="1">
          <Sider className="app-sider" trigger={null} collapsible collapsed={this.state.collapsed}>
            <div style={{minHeight: '4em', textAlign: 'center'}}>
              <img src={rslogo} style={{maxWidth: '-webkit-fill-available', maxHeight: '4em'}} alt='RosterSpot Logo'/>
            </div>
            <Menu theme="dark" mode="inline" selectedKeys={[menuIndex]}>
              {_.map(routes, (item, idx) => {
                return (
                  <Menu.Item key={idx} className="d-flex align-items-center menu-item my-0">
                    <NavLink exact to={item.path} >
                      <Icon style={{ fontSize: 18 }} type={item.icon} />
                      <span style={{ fontSize: 16 }}>{item.name}</span>
                    </NavLink>
                  </Menu.Item>
                )
              })}
            </Menu>
          </Sider>
          <Layout className="d-flex flex-column" key="2">
            <Header theme="dark" className="px-4  d-flex align-items-center justify-content-between">
              <Icon
                className="trigger text-light"
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={() => this.setState({ collapsed: !this.state.collapsed })}
              />
              <Profile currentLogin={currentLogin} onClick={() => this.setState({ profileToggle: true })} />
            </Header>
            <Content className="d-flex flex-column flex-fill">
              <Breadcrumb className="mx-4 mt-2">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                {_.split(window.location.pathname, '/').filter(item => item !== 'data')
                  .map((item, idx) => <Breadcrumb.Item key={idx}>{_.capitalize(_.lowerCase(item))}</Breadcrumb.Item>)}
              </Breadcrumb>
              <div className="mx-4 mt-2 d-flex flex-column flex-fill">
                {this.props.children}
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }} className="bg-white mt-4">.Team ©2018 Created by RosterSpot</Footer>
          </Layout>
        </Layout>
        <ProfileDrawer
          onLogoutClick={() => authorizationStore.logout({
            redirect: () => this.props.history.push(routePath.login)
          })}
          currentLogin={currentLogin}
          visible={this.state.profileToggle}
          onClose={() => this.setState({ profileToggle: false })} />
      </Layout>
    )
  }
}

const Profile = React.memo(({ currentLogin, onClick }) => {
  return (
    <a style={{ userSelect: 'none' }} className="text-light" onClick={onClick}>
      <Avatar style={{ backgroundColor: '#f56a00', verticalAlign: 'middle', fontSize: 18 }} src={currentLogin.avatar} size="large">{_.get(currentLogin, "name[0]")}</Avatar>
      <span className="ml-2">{currentLogin.name}</span>
    </a>
  )
}, (prevProps, nextProps) => {
  return prevProps.currentLogin.id === nextProps.currentLogin.id
})

export default withRouter(Container)