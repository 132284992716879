import http from "../common/request";
import abp from "../common/abp";
import _ from 'lodash';
import requestV3 from "../common/requestV3";

class AuthorizationService {
  async authorization(login) {
    return await requestV3.post('/account.login', login);
  }

  isAuthorized() {
    return !!abp.auth.getToken()
  }

  async hasPermistions(roles) {
    const role_string = (roles || []).join(',');
    return await http.post('/auth/permisstions/grant', role_string);
  }

  fetchRoles() {
    return requestV3.get('role.getall')
  }

  fetchRolesByUser() {
    return requestV3.get('/role.mine');
  }
}

export default new AuthorizationService();