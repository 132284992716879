import LoadingScreen from '@components/LoadingScreen';
import { Spin } from 'antd';
import Loadable from 'react-loadable';

const WithLoadable = component => {
  return Loadable({
    loader: component,
    loading: () => <Spin className="layout-spin" />
  })
}

export default WithLoadable;