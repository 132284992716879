import http from "@libs/common/request";
import requestV2 from "@libs/common/requestV2";

class UserFinderService {
  find(name) {
    return http.get("/user-finder", {
      params: {
        text_search: name
      }
    });
  }

  findByGroup(searchModel) {
    return requestV2.get('common/user/finder', { params: searchModel })
  }
}

export default new UserFinderService();