const actionTypes = {
  TOGGLE_LOAYOUT_LOADING: '@@LAYOUT_TOGGLE_LOAYOUT_LOADING'
}

const toggleLayoutLoading = show => ({
  type: actionTypes.TOGGLE_LOAYOUT_LOADING,
  show
})

export default {
  actionTypes,
  toggleLayoutLoading
}