import { observable, action, toJS } from "mobx";

export default class SessionStore {
    @observable _currentLogin = {
        full_name: '',
        email: '',
        avatar: null
    };

    get currentLogin() {
        return toJS(this._currentLogin);
    }

    @action
    setCurrentLogin(loginModel) {
        this._currentLogin = loginModel;
    }
}