const Stores = {
  AthleteGridStore: "athleteGridStore",
  StateStore: "stateStore",
  AthleteDetailStore: "athleteDetailStore",
  AuthorizationStore: 'authorizationStore',
  LoginStore: 'loginStore',
  SessionStore: 'sessionStore',
  RecoveryStore: 'recoveryStore',
  HighSchoolStore: 'highSchoolStore',
  RegisterStore: 'registerStore',
  CountyStore: 'countyStore',
  ForgotPasswordStore: 'forgotPasswordStore',
  PositionModalStore: 'positionModalStore',
  StatModalStore: 'statModalStore',
  CoachHighSchoolModalStore: 'coachHighSchoolModalStore',
  CollegeGridStore: 'collegeGridStore',
  CoachCollegeStore: 'coachCollegeStore',
  UserInfomationStore: 'userInfomationStore',
  PositionStore: 'positionStore',
  YearStore: 'yearStore',
  PositionAutoComplete: 'positionAutoComplete',
  RoleStore: 'roleStore',
  UserStore: 'userStore',
  PostStore: 'postStore',
  UserConvertStore: 'userConvertStore'
};

export default Stores;