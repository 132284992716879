import { observable, computed, action, toJS } from "mobx";
import countyService from "@libs/services/CountyService";

export default class CountyStore {
  @observable _counties = [];

  @computed
  get counties() {
    return toJS(this._counties);
  }

  @action
  async fetchCounties(stateId, name) {
    this._counties = await countyService.getCountiesAutoComplete(stateId, name);
  }

  @action
  fetchCountiesByStates = async state_ids => {
    this._counties = await countyService.getCountiesByState(state_ids);
  }
}