import http from "../common/request";

export default class AssessmentService {
  async getAssessments() {
    return await http.get('/assessment');
  }

  async addUpdateAssessment(assessments) {
    return await http.post('/assessment', { assessments });
  }

  async getAthleteAssessment(athleteId) {
    let url = '/assessment/athlete'
    const data = { params: { athlete_id: athleteId } };
    return await http.get(url, data);
  }

  async updateAthleteAssessment(assessments, athleteId) {
    let url = '/assessment/athlete'
    return await http.post(url, {
      assessments: assessments,
      athlete_id: athleteId
    });
  }
}