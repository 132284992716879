import layoutActions from "@stores/actions/layoutActions";

const initialState = {
  layoutLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case layoutActions.actionTypes.TOGGLE_LOAYOUT_LOADING:
      return {
        ...state,
        layoutLoading: action.show
      }

    default:
      return state
  }
}